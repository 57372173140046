// 1. Vendors
@import  '00_abstract/variables.scss';
@import  '00_abstract/functions.scss';
@import  '00_abstract/mixins/mixins.scss';
@import  '01_vendor/_01_normalize.scss';

// 2. Layout-related sections
@import  '02_layout/_01_header';
@import  '02_layout/_02_footer';
@import  '02_layout/_03_pages';

// 3. Page-specific styles
@import '03_pages/01_common';
