body {
    // background-color: #F4F6F8;
}
.sidebar_logo{
  position: relative;
  .stars{
    background-image: url('/wp-content/themes/rubindesignnou/assets/img/stars_shadow.png');
    background-size: 90% 90%;
    background-repeat: no-repeat;
    color: #151c20;
    font-size: 25px;
    font-weight: 600;
    height: 50px;
    left: 0;
    position: absolute;
    padding-left: 15px;
    padding-top: 3px;
    top: -25px;
    text-align: left;
    width: 70px;
    span {
      display: block;
      margin-left: -6px;
      text-align: center;
      width: 25px;
    }
  }
}

.review-singles {
  section {
    p {
      line-height: 1.8;
      font-size: 18px;
      font-weight: 300;
      color: #3d4347;
    }
  }
}

.review-singles {
  .general-content {
    ul {
      li {
        color: #3d4347;
        font-size: 15px;
      }
    }
    .general-text-dynamic {
      .wpb_wrapper {
        p {
          line-height: 1.8;
          font-size: 18px;
          font-weight: 300;
          color: #3d4347;
          span {
            font-weight: 300 !important;
          }
        }
      }
    }
  }
  .module {
    h1 {
      color: #3d4347;
      font-size: 44px;
      line-height: 55px;
    }
    h2 {
      color: #3d4347;
      font-size: 36px;
    }
    p {
      span {
        font-weight: 300 !important;
      }
    }
  }
}

.review-singles {
  .general-content {
.text__list {
  display: flex;
  flex-wrap: wrap;
  $this : &;
  h3 {
    font-size: 24px;
    color:#fff;
    text-align: center;
  }

  &--row {

    @include media_min(md) {
      margin: 0px -30px;
    }

    #{$this}__item {
      width: calc(100% / 2 - 45px);
      border-radius: 5px;
      margin-bottom: 30px;
      border: 1px solid color(gy, 3);

      @include media(md) {
        width: calc(100% / 1);
      }
      @include media_min(md) {
        margin: 0px 30px;
      }

      &:nth-child(odd),
      &:nth-child(even) {
        box-shadow: 0px 4px 5px rgba(color(bk, 4), 0.05);
      }


    }

    #{$this}__title {
      background: color(bk, 3);
      padding-left: 20px;
      font-weight: 600;
      span {
        color: color(gr, 1);
        width: 25px;
      }
    }

    #{$this}__el {
      list-style-type: decimal;
      li {
        display: list-item;
        padding-left: 10px;
        margin-left: 10px;
      }
    }
  }

  &__item {
    width: calc(100% / 2);
    box-shadow: inset 0px 0px 0px 1px rgba(color(gr, 1), 0.5);
    overflow: hidden;

    @include media(md) {
      width: 100%;
      border-radius: 5px;
    }

    @include media_min(md) {
      &:nth-child(odd) {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
      &:nth-child(even) {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
    .pros {
      margin-left: 20px;

      li {
        list-style: disc outside none;
        display: list-item;
        list-style-image: url(/wp-content/themes/rubindesignnou/assets/img/check.svg);
        font-size: 18px;
        line-height: 1.38;
        color: #3a4a52;
        font-weight: 300;
      }
    }
    .cons {
      margin-left: 20px;
      li {
        list-style: disc outside none;
        display: list-item;
        list-style-image: url(/wp-content/themes/rubindesignnou/assets/img/x.svg);
        font-size: 18px;
        line-height: 1.38;
        color: #3a4a52;
        font-weight: 300;
      }

    }

    li {
      font-size: 15px;
      line-height: 18px;
      padding: 7px 0px;
      display: flex;
      align-items: center;
      &:before {
        color: color(gr, 1);
        margin-right: 10px;
        font-size: 12px;
      }
    }

    &:nth-child(even) {
      margin-left: -1px;
      box-shadow: inset 0px 0px 0px 1px rgba(color(rd, 1), 0.5);
      li {
        &:before {
          color: color(rd, 1);
        }
      }
    }
  }

  &__title {
    margin: 0px;
    padding: 0px;
    line-height: 25px;
    min-height: 40px;
    color: color(wh, 1);
    padding-left: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

  }


  &__el {
    color: color(bk, 4);
    padding: 25px;
    margin: 0px;
    list-style-type: none;
  }
}

.links-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0px -10px;


  &__item {
    width: calc(100% / 2 - 20px);
    @include media(md) {
      width: 100%;
    }

    margin: 0px 10px;
    display: flex;
    box-shadow: 0px 1px 9px -5px color(bk, 1);
    margin-bottom: 20px;
    overflow: hidden;
    background: color(wh, 1);
    border-radius: 5px;

    @if $isMobile {
      width: 100%;
      display: block;
    }

  }

  &__image {
    width: 180px;
    position: relative;
    @if $isMobile {
      width: 100%;
      height: 100px;
    }
    img {
      object-fit: cover;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
    }
  }

  &__content {
    padding: 15px;
    flex: 1;

    @if $isMobile {
      width: 100%;
    }

    h3 {
      margin-top: 0px;
      font-weight: 600;
      margin-bottom: 10px;
    }

    p {
      margin: 0px;
      margin-top: 10px;
      font-size: 15px;
    }
  }

  &__action {
    display: flex;
    align-items: center;
    padding-right: 15px;

    @if $isMobile {
      justify-content: center;
      padding-bottom: 30px;
    }
  }
}
  }}

.general-promotions {
  /*border-bottom: none;*/
  .text {
    margin-bottom: 30px;
  }


  .general-promotions-box {
    margin-bottom: 20px;
    height: inherit !important;

    a {
      text-decoration: none;
    }
  }
}
.upb_video_class, .vc_row, .wpb_column, .wpb_row {
  overflow: hidden;
}
.new-design.general-content-box .module {
  margin-top: 50px;
}

.sports-welcome-bonus-details-content {
  .true-value-bonus {
    .title {
      p {
        color: #fec02a;
      }
    }
  }
  .bonuses {
    .title {
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        color: #fec02a;
        margin-right: 5px;
        font-size: 17px;
      }
      span {
        color: #fec02a;
        line-height: 17px;
        height: auto;
        padding: 0;
        padding-top: 0 !important;
      }
    }
  }
}
.comment-form-cookies-consent {
  font-size: 18px;
  color: #000;
  font-weight: 300;
  margin-top: 4px;
  margin-bottom: 15px;
  text-align: left;
  display: block;
  padding-left: 20px;
  label {
    font-weight: 300;
  }
}

.review-singles .general-content ul li {
  line-height: 1.8;
  font-size: 18px;
  font-weight: 300;
  color: #3d4347;
}