.headerFirstRow{
  background-color: #272e33;
}

.rd-separator{
  background-color: #272e33;
  border-top: 1px solid #3A4A52;
  height: 30px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.searchTarget{
  position: absolute;
  background-color: #2b3338;
  z-index: 999999;
  overflow: hidden;
  &.expanded{
    width: 100%;
    height: 100vh;
  }
  input{
    position: absolute;
    border: none;
    color: #fff;
    font-size: 16px;
    top: 50px;
    left: 20px;
    font-weight: 400;
    border-radius: 3px;
    width: calc(100% - 100px);
    height: 40px;
    background: rgba(255, 255, 255, 0.1) url(/wp-content/themes/rubindesignnou/assets/img/search-icon-search.svg) no-repeat 15px 50%;
    outline: none;
    padding: 8px 10px 8px 42px;
    z-index: 1;
    box-shadow: 0 7px 13px 0 rgba(29, 29, 44, 0);
  }
  #searchCloseMobile{
    position: absolute;
    right: 20px;
    top: 60px;
    font-size: 14px;
    font-weight: 600;
    color: #ffffff;
  }
}

.breadcrumbs{
  li{
    a{
      text-decoration: underline;
      color: #fff;
      &.lastBread{
        text-decoration: none;
      }
    }
  }
}

.headerSecondRow{
  background-color: #272e33;
  @media all and (max-width:991px) {
    display: none;
  }
  .locationTarget {
    display: none;
  }
  .subMenuActivator{
    display: none;
  }
  .infos{
    @media all and (max-width:991px){
      padding-top: 95px;
      padding-left: 45px;
      padding-right: 45px;
    }
  }
  &.menuOpen{
    @media all and (max-width:991px) {
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100vh;
      overflow: scroll;
      padding-top: 53px;
      z-index: 9999;
    }
    .subMenuBack{
      @media all and (max-width:991px) {
        position: absolute;
        right: 10px;
      }

    }
    .subMenuTarget{
      @media all and (max-width:991px) {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        padding-right: 60px;
        padding-left: 60px;
        z-index: 999;
        height: 0;
        border: 0;
        background-color: #2b3338;
        overflow: scroll;
      }
      .list-unstyled{
        @media all and (max-width:991px) {
          position: relative;
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          flex-wrap: wrap;
          width: 100%;
          height: 100%;
          box-shadow: none;
          border: 0;
          background-color: #2b3338;
          top: 0;
          padding: 0;
          -webkit-transition: all .3s ease-in-out;
          -moz-transition: all .3s ease-in-out;
          -o-transition: all .3s ease-in-out;
          transition: all .3s ease-in-out;
        }
        p {
          &.parentSubmenu {
            @media all and (max-width:991px) {
              display: block;
              font-size: 18px;
              font-weight: bold;
              padding-left: 15px;
              color: #ffffff;
            }
          }
        }
        .subMenuLinks{
          @media all and (max-width:991px) {
            order: 2;
            width: 100%;
            margin-top: 15px;
          }
          a{
            @media all and (max-width:991px) {
              display: block;
              font-size: 14px;
              font-weight: 400;
              padding-top: 9px;
              padding-bottom: 9px;
              line-height: normal;
              letter-spacing: normal;
              color: #ffffff;
            }
          }
        }
        .adsMenu{
          @media all and (max-width:991px) {
            order: 1;
            width: 100%;
          }
          >div{
            @media all and (max-width:991px) {
              padding: 0;
            }
          }
          a{
            @media all and (max-width:991px) {
              display: flex;
              align-items: center;
              margin-bottom: 10px;
            }
            img{
              @media all and (max-width:991px) {
                max-width: 120px;
              }
            }
          }
          p{
            &.parentSubmenu{
              @media all and (max-width:991px) {
                display: block;
                font-size: 18px;
                font-weight: bold;
                padding-left: 15px;
                color: #ffffff;
              }
            }
            &.h2{
              @media all and (max-width:991px) {
                font-size: 18px;
                margin-top: 9px;
                font-weight: 400;
                color: #869096;
              }
            }
            &.h4{
              @media all and (max-width:991px) {
                margin: 0 0 0 15px;
                color: #66bb6a;
              }
            }
            &.h5{
              @media all and (max-width:991px) {
                display: none;
              }
            }
          }
        }
        a{
          @media all and (max-width:991px) {
            color: #ffffff !important;
          }
        }
      }
    }
    .locationTarget {
      @media all and (max-width:991px) {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100vh;
        background-color: #2b3338;
        border: 0;
        overflow: scroll;
        z-index: 9999;
      }
      @media all and (max-width:768px) {
        padding-left: 60px;
        padding-right: 60px;
      }
      .bs-searchbox{
        @media all and (max-width:991px) {
          padding: 4px 0;
        }
      }
      input{
        @media all and (max-width:991px) {
          background: transparent url("/wp-content/themes/rubindesignnou/assets/img/search-icon-search.svg") no-repeat 10px 50%;
          border: 0;
          padding: 0 30px;
          border-bottom: 2px solid #3d4347;
        }
        &:focus{
          @media all and (max-width:991px) {
            box-shadow: none;
          }
        }
      }
      button{
        @media all and (max-width:991px) {
          display: none;
        }
      }
      .bootstrap-select{
        @media all and (max-width:991px) {
          width: 100%;
        }
        .dropdown-menu{
          @media all and (max-width:991px) {
            display: block;
            position: relative;
            max-height: 75vh !important;
            background-color: transparent;
            border: 0;
            box-shadow: none;
            &.inner{
              top: 50px;
            }
          }
          li{
            .country-image{
              @media all and (max-width:991px) {
                margin-right: 13px;
              }
            }
            a{
              @media all and (max-width:991px) {
                padding: 4px 0;
                color: #869096;
              }
              &:hover{
                @media all and (max-width:991px) {
                  background-color: transparent;
                }
              }
            }
          }
          >.active{
            >a{
              @media all and (max-width:991px) {
                background-color: transparent;
              }
            }
          }
        }
      }
      .locationBack{
        @media all and (max-width:991px) {
          position: absolute;
          top: 3px;
          right: 60px;
        }
      }
      .locationTitle {
        @media all and (max-width:991px) {
          font-size: 18px;
          font-weight: bold;
          color: #ffffff;
        }
      }
    }
    .navbar-nav{
      @media all and (max-width:991px) {
        display: block;
        padding-bottom: 110px;
      }
      >li{
        @media all and (max-width:991px) {
          text-align: left;
          padding-right: 10%;
        }
        &.menu-item-has-children{
          &:hover{
            &:before{
              @media all and (max-width:991px) {
                -webkit-transform: rotate(-135deg);
                -moz-transform: rotate(-135deg);
                -o-transform: rotate(-135deg);
                -ms-transform: rotate(-135deg);
                transform: rotateZ(-135deg);
              }
            }
          }
        }
      }
      li{
        &.menu-item-has-children{
          .subMenuActivator{
            @media all and (max-width:991px) {
              display: inline-block;
              height: 10px;
              position: absolute;
              top: 8px;
              right: 0;
              text-align: center;
              -webkit-transition: all .3s ease-in-out;
              -moz-transition: all .3s ease-in-out;
              -o-transition: all .3s ease-in-out;
              transition: all .3s ease-in-out;
            }
          }
        }
        .list-unstyled{
          @media all and (max-width:991px) {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            height: 0;
            top: 0;
            padding: 0;
            -webkit-transition: all .3s ease-in-out;
            -moz-transition: all .3s ease-in-out;
            -o-transition: all .3s ease-in-out;
            transition: all .3s ease-in-out;
          }
          .subMenuLinks{
            @media all and (max-width:991px) {
              order: 2;
              width: 100%;
            }
          }
          .adsMenu{
            @media all and (max-width:991px) {
              order: 1;
              width: 100%;
            }
            a{
              @media all and (max-width:991px) {
                display: flex;
                align-items: center;
              }
            }
            p{
              &.parentSubmenu{
                @media all and (max-width:991px) {
                  display: block;
                }
              }
              &.h4{
                @media all and (max-width:991px) {
                  margin: 0 0 0 15px;
                }
              }
              &.h5{
                @media all and (max-width:991px) {
                  display: none;
                }
              }
            }
          }
          a{
            @media all and (max-width:991px) {
              color: #ffffff !important;
            }
          }
        }
        .dropdown-menu{
          @media all and (max-width:991px) {
            background-color: transparent;
            max-height: initial;
          }
        }
      }
    }
  }

  .navbar-nav{
    display: flex;
    flex-wrap: wrap;
    margin-left: 0;
    width: 100%;
    >li{
      flex: 1;
      text-align: center;
      &:hover{
        background-color: #383f44;
        @media all and (max-width:991px) {
          background-color: #3a4a52;
        }
      }
    }
    li{
      a{
        font-size: 14px;
        font-weight: 600;
        line-height: 1.29;
        padding: 25px 0 27px;
        padding-top: 25px;
        padding-bottom: 27px;
        color: #ffffff;
        &:hover,&:focus{
          background-color: transparent;
        }
      }
      .dropdown-menu{
        background-color: #383f44;
      }
      .list-unstyled{
        position: absolute;
        width: 1140px;
        top: 70px;
        padding: 30px 15px;
        border: 0;
        p{
          &.parentSubmenu{
            display: none;
          }
          &.h2{
            font-size: 18px;
            font-weight: 600;
            letter-spacing: normal;
            color: #ffffff;
            margin-top: 0;
            margin-bottom: 26px;
            padding-left: 15px;
          }
          &.h4{
            font-size: 14px;
            font-weight: 600;
            line-height: 1.2;
            color: #65bb6a;
            margin-top: 20px;
            margin-bottom: 1px;
            &:hover{
              color: #419345;
              text-decoration: underline;
            }
          }
          &.h5{
            font-size: 14px;
            font-weight: 400;
            line-height: 1.2;
            color: #869096;
            margin-top: 0px;
          }
        }
        .adsMenu{
          a{
            font-size: 14px;
            font-weight: 400;
            line-height: 1.2;
            color: #869096;
            padding: 0;
            &:hover{
              text-decoration: none;
            }
          }
        }
        li{
          >a{
            display: block;
            font-size: 14px;
            font-weight: 400;
            line-height: 1.1;
            padding: 0;
            color: #869096;
            margin-bottom: 12px;
            &:hover{
              color: #ffffff;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}

.navbar-default{
  background-color: transparent;
  border-color: transparent;
  @media all and (max-width:991px){
    .bonuses{
      display: none;
    }
    #header-selectpicker{
      display: none;
    }
  }
  .menuTitle{
    display: none;
    position: absolute;
    top: 53px;
    left: 73px;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    z-index: 10000;
  }
  .navbar-toggle{
    width: 40px;
    height: 30px;
    top: 31px;
    border: 0;
    z-index: 10000;
    border-radius: 3px;
    background-color: transparent;
    -webkit-transition: .3s ease-in-out;
    -moz-transition: .3s ease-in-out;
    -o-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    &:hover, &:focus{
      background-color: transparent;
    }
    @media all and (max-width:991px){
      margin-top: 8px;
      top: 45px;
      right: 57px;
    }
    &.collapsed{
      @media all and (max-width:991px){
        top: 7px;
        right: 0px;
      }
      @media all and (max-width:479px){
        right: 15px;
      }
    }
    .icon-bar{
      background-color: #869096;
      margin: 0;
      -webkit-transition: .25s ease-in-out;
      -moz-transition: .25s ease-in-out;
      -o-transition: .25s ease-in-out;
      transition: .25s ease-in-out;
    }
    span{
      position: absolute;
      &:nth-of-type(2) {
        top: 13px;
        background-color: #65bb6a;
        -webkit-transform: rotate(135deg);
        -moz-transform: rotate(135deg);
        -o-transform: rotate(135deg);
        transform: rotate(135deg);
      }
      &:nth-of-type(3) {
        opacity: 0;
        left: -60px;
      }

      &:nth-of-type(4) {
        top: 13px;
        background-color: #65bb6a;
        -webkit-transform: rotate(-135deg);
        -moz-transform: rotate(-135deg);
        -o-transform: rotate(-135deg);
        transform: rotate(-135deg);
      }
    }
    &.collapsed{
      background-color: rgba(255,255,255,0.1);
      span{
        &:nth-of-type(2) {
          top: 8px;
          background-color: #869096;
          -webkit-transform: rotate(0deg);
          -moz-transform: rotate(0deg);
          -o-transform: rotate(0deg);
          transform: rotate(0deg);
        }
        &:nth-of-type(3) {
          top: 14px;
          opacity: 1;
          left: 13px;
          width: 18px;
          margin: 0;
        }

        &:nth-of-type(4) {
          top: 20px;
          background-color: #869096;
          -webkit-transform: rotate(0deg);
          -moz-transform: rotate(0deg);
          -o-transform: rotate(0deg);
          transform: rotate(0deg);
        }
      }
    }
  }
}
.header-navigation-box {
  .s-wrap{
    float: right;
    position: relative;
    width: 170px;
    height: 40px;
    top: 2px;
    right: 0;
    &.expanded{
      position: absolute;
      z-index: 999;
      background: #272e33;
      width: 75%;
      #searchClose{
        background: url(/wp-content/themes/rubindesignnou/assets/img/close.svg) no-repeat 50%;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 10px;
        width: 29px;
        opacity: 1;
      }
      form{
        width: 100%;
        input{
          width: 100% !important;
          transition: all 0.2s ease-in-out;
        }
      }
    }
    @media all and (max-width:768px){
      float: none;
      position: absolute;
      width: auto;
      height: auto;
      top: 15px;
      right: 15px;
    }
  }
  .q-search{
    @media all and (max-width:991px){
      left: initial;
      right: 49px;
      top: 13px;
    }
    @media all and (max-width:768px){
      top: 0;
    }
    input[type="text"]{
      position: relative;
      padding-right: 10px;
      padding-left: 31px;
      width: 170px;
      height: 40px;
      border-radius: 3px;
      background: rgba(255, 255, 255, 0.1) url('/wp-content/themes/rubindesignnou/assets/img/search-icon-search.svg') no-repeat 10px 50%;
      box-shadow: 0 7px 13px 0 rgba(29, 29, 44, 0);
      &:focus{
        position: relative;
        padding-right: 10px;
        padding-left: 31px;
        width: 170px;
        height: 40px;
        border-radius: 3px;
        background: rgba(255, 255, 255, 0.1) url('/wp-content/themes/rubindesignnou/assets/img/search-icon-search.svg') no-repeat 10px 50%;
        box-shadow: 0 7px 13px 0 rgba(29, 29, 44, 0);
        background-color: rgba(255, 255, 255, 0.1);
      }
      &::placeholder{
        color: #869096;
      }
      @media all and (max-width:991px){
        width: 40px;
        height: 30px;
        background: rgba(255, 255, 255, 0.1) url('/wp-content/themes/rubindesignnou/assets/img/search-icon-search.svg') no-repeat 14px 50%;
        &:focus{
          height: 30px;
        }
      }
    }
  }
  .header-logo{
    width: 100%;
    margin-top: 21px;
    margin-bottom: 8px;
    @media all and (max-width:991px){
      width: 44px;
      min-width: 44px;
      margin-top: 8px;
    }
  }
  .infos{
    margin-top: 5px;
    margin-right: 10px;
    .bonuses {
      margin-right: 31px;
      p{
        font-weight: 600;
        line-height: 1.29;
        color: #ffffff;
      }
      .values{
        font-size: 22px;
        font-weight: 600;
        line-height: 1.27;
        color: #65bb6a;
      }
    }
    .dropdown-menu{
      @media all and (max-width:991px){
        display: none;
      }
    }
    .country{
      .bootstrap-select{
        &.btn-group{
          .btn{
            .filter-option{
              width: auto;
            }
            .caret{
              &:before{
                top: 8px;
                margin-left: -8px;
                border-top: 2px solid #869096;
                border-left: 2px solid #869096;
                @media all and (max-width:991px){
                  top: 10px;
                  -webkit-transform:rotate(-135deg);
                  -moz-transform:rotate(-225deg);
                  -o-transform:rotate(-225deg);
                  -ms-transform:rotate(-225deg);
                  transform:rotateZ(-225deg);
                }
                @media all and (max-width:991px){
                  top: 6px;
                  opacity: 0.4;
                }
              }
            }
          }
        }
        .filter-option{
          .country-image{
            display: inline-block;
          }
          .country-short-name{
            font-size: 14px;
            font-weight: 600;
            line-height: 1.29;
            margin-left: 5px;
            margin-right: 10px;
            color: #ffffff;
          }
        }
        .dropdown-toggle{
          width: 240px;
          height: 40px;
          border-color: transparent;
          border-radius: 3px;
          box-shadow: 0 7px 13px 0 rgba(29, 29, 44, 0);
          background-color: rgba(255, 255, 255, 0.1);
          @media all and (max-width:991px){
            width: 100%;
            background-color: #272e33;
          }
          .changeLocationText{
            float: left;
            font-size: 14px;
            font-weight: 400;
            line-height: 1.29;
            color: #869096;
            padding-left: 8px;
            border-left: 1px solid #869096;
            &.reloadedSelect{
              margin-top: 3px;
            }
          }
        }
      }
    }
  }
}
.locationHtml{
  li {
    &.selected {
      color: #ffffff;
      span{
        color: #ffffff;
      }
    }
  }
}
#header-selectpicker{
  position: relative;
  margin-top: -3px;
  @media all and (max-width:991px){
    position: absolute;
    bottom: 0;
    width: calc(100% + 120px);
  }
  .bootstrap-select{
    width: 100%;
    height: 40px;
    border-radius: 3px;
    box-shadow: 0 7px 13px 0 rgba(29, 29, 44, 0);
    .caret{
      @media all and (max-width:991px){
        right: 120px;
      }
    }
    &.hide-us {
      .filter-option {
        display: none;
      }
      .changeLocationText {
        border-left: none;
      }
    }
  }
  .dropdown-menu{
    width: 100%;
    height: 184px;
    max-height: 184px;
    top: 48px;
    padding-top: 0;
    padding-bottom: 15px;
    border-radius: 3px;
    box-shadow: 0 7px 13px 0 rgba(29, 29, 44, 0);
    background-color: #383f44;
    .mCustomScrollBox{
      height: auto;
    }
    .mCSB_scrollTools{
      .mCSB_draggerRail{
        width: 6px;
      }
      .mCSB_dragger{
        .mCSB_dragger_bar{
          background-color: #3d4347;
        }
      }
    }
    &.inner{
      li{
        &.selected{
          color: #4f6875;
        }
        >a{
          display: block;
          padding: 3px 20px;
          clear: both;
          font-weight: normal;
          line-height: 1.42857;
          color: #869096;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
    .bs-searchbox{
      background-color: #232a2f;
      margin-bottom: 15px;
      .searchIcon{
        position: absolute;
        right: 10px;
        top: 11px;
      }
    }
    .form-control{
      border: 0 transparent;
      background-color: transparent;
      box-shadow: initial;
      &:focus{
        box-shadow: initial;
      }
    }
  }
}

//.changeLocationText{
//  position: absolute;
//  top: 0;
//  bottom: 0;
//  left: 73px;
//  height: 18px;
//  margin: auto;
//  font-size: 14px;
//  font-weight: 400;
//  line-height: 1.29;
//  color: #869096;
//  padding-left: 8px;
//  border-left: 1px solid #869096;
//}

.breadcrumbs{
  li{
    span{
      font-size: 13px;
      line-height: 30px;
      color: #fff;
    }
  }
}

.bannerRow{
  background-color: #2b3338;
  a{
    display: inline-block;
    float: left;
    width: 50%;
    @media all and (max-width:768px) {
      width: 100%;
      float: none;
    }

    &:first-child {
      img, video {
        margin-left: auto;
      }
    }

    video, img {
      display: block;
      max-width: 100%;
      height: auto;
    }
  }
}
#q {
  z-index:10;
}


.container {
  &--large {
    width: 100%;
    max-width: 1620px;
  }
}

.headerSecondRow .navbar-nav li .list-unstyled {
  width: 100%;
}

.header-navigation-box .header-logo {
  width: 100%;
  margin-top: 15px;
  margin-bottom: 20px;
  height: 50px;
}

.header-logo-image {
  a {
    display: inline-block;
  }

  svg {
    width: 195px;
    padding: 10px 0px 10px 15px;
    fill: #fff;

    .st1 {
      fill: #66bb6a;
    }
  }
}

.navbar-toggle .icon-bar {
  height: 3px;
}

.navbar-default .navbar-toggle.collapsed span:nth-of-type(3) {
  left: 14px;
}

.header-navigation-box .infos .bonuses p {
  font-size: 14px;
}