
.recommendedCasinos {
  background-color: #f4f4f4;
  padding-top: 1px;
  padding-bottom: 90px;
  border-radius: 0px 0px 8px 8px ;
  margin-bottom: 30px;
  .responseTarget{
    font-size: 15px;
    font-weight: 600;
    line-height: 1.2;
    text-align: center;
    color: #000;
  }
  .bonus-line {
    display: flex;
    background-color: #f4f4f4;
    margin:5px;
    align-items: center;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
    .recommendedCasinoRank{
      width: 20px;
      height: 20px;
      border-radius: 25%;
      background-color: #AAAAAA;
      color: #000;
      text-align: center;
      margin: auto 0;

    }

    .recommendedCasinoLogo{
      margin-top: 5px;
      margin-left: 10px;
      a{
        display: inline-block;
        width: 64px;
        height: 34px;
        background-size: cover;
        background-position: center;
        border-radius: 6px;

      }

    }
    .sidebar_description {
      color:#000;
      width: 70px;
      margin: 0;
      font-weight: bold;
      font-size: 15px;
      line-height: 13px;
      margin-left: 10px;
      text-align: left;
      word-break: break-word;
    }

    .sidebar_button {
      a {
        font-size: 13px;
        border-radius: 6px;
        padding: 7px 14px;
      }
    }

  }

  .col-xs-12 {
    text-align: center;
    margin-top: 25px;
    .btn-warning {
      text-transform:capitalize;
      font-weight: 600;
      width: 200px;
      padding: 14px 0px;
      height: 44px;
      font-size: 13px;
      border-radius: 6px;
    }
  }
  .just-on-xs {
    p {
      font-size: 20px;
      font-weight: 300;
      margin: 7px 0px 0px;
      padding: 0;
      color: #000;
    }
  }
}

@media screen and (max-width: 767px) {
  .general-sidebar-country-select .bootstrap-select {
    width: 100% !important;
    max-width: 100%;
  }
  .general-sidebar-country-select .open .dropdown-menu {
    width: 100%;
  }
}

.article-text {
  text-align: left !important;
  p {
      text-align: justify;
  }
}

.single-articles .article-title {
  padding: 0px 15px;
}

.recommendedCasinos .bonus-line .sidebar_description {
  word-break: break-word;
}

.general-top-box {
  .general-info-product {
    img.floated-img {
      &:last-child {
        position: static;
        width: 28px;
        height: 28px;
        margin-right: 5px;
        
        @media only screen and (max-width: 767px) {
          position: static !important;
        }
      }
    }
  }
}