.mastfooter {
    background: color(bk, 1);
    overflow: hidden;

    .crumbs {
        height:40px;
        background: lighten(color(bk, 1), 2%);
        margin: 0px -15px;
        padding: 0px 15px;
        font-size: 13px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        &, a {
            color: color(gy, 1);
        }

        a {
            text-decoration: underline;
        }

        .sep {
            padding: 0px 5px;
        }
    }

    &__top {
        display: flex;
        flex-wrap: wrap;
        padding: 50px 0px;
        border-bottom: 1px solid color(bk, 7);
        @include media(xs) {
            padding-top: 20px;
            padding-bottom: 0px;
        }
    }

    &__col {

        ul {
            margin: 0px;
            padding: 0px;
            list-style-type: none;
        }

        &--3 {
            width: calc(100% / 3);
            @if $isMobile == false {
                @include media(lg) {
                    width: 100%;
                    margin-bottom: 30px;
                    text-align: center;
                }
            } @else {
                width: 100%;
                margin-bottom: 30px;
                text-align: center;
            }
            
            p {
                font-size: 18px;
                line-height: 25px;
            }
            svg {
                max-width: 300px;
                @include media(xs) {
                    max-width: 200px;
                }
                
            }
        }

        &--2 {
            @if $isMobile == false {
                @include media_min(lg) {
                    width: 70%;
                    &:last-child {
                        width: 30%;
                    }
                }
            }           
        }
    }

    &__reviews, 
    &__social {   
        p {
            color: color(wh, 1);
            text-align: center;
            margin: 0px;
            margin-bottom: 15px;
        }

        ul.reviews__ {
            text-align: center;
            display: flex;
            flex-wrap: wrap;
            background: color(bk, 6);
            box-shadow: 0px 0px 0px 1px color(bk, 7);
            border-radius: 3px;
            li {
                width: calc(100% / 3);
                text-align: center;
                padding: 10px 0px;
                &:nth-child(1),
                &:nth-child(2) {
                    box-shadow: 1px 0px 0px 0px color(bk, 7);
                }
                
                p {
                    color: color(gy, 2);
                    padding: 0px;
                    margin: 0px;
                    font-size: 13px;
                    line-height: 18px;
                    span {
                        font-size: 18px;
                        color: color(gr, 1);
                        font-weight: 600;
                        display: block;
                    }
                }                
            }
        }
    }

    &__social {
        
        ul {
            display: flex;
            flex-wrap: wrap;
            width: 150px;
            margin: auto;
            padding-top: 10px;
            justify-content: center;
            @include media(xs) {
                width: 200px;
            }

            li {
                width: calc(100% / 3);
                a {
                    width: 35px;
                    height: 35px;
                    border-radius: 50px;
                    background: rgba(color(wh, 1), .4);
                    position: relative;
                    display: block;
                    margin: auto;
                    &:hover {
                        background: rgba(color(wh, 1), 1);
                    }
                    i {
                        position: absolute;
                        top: 9px;
                        text-align: center;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        font-size: 18px;
                        color: color(bk, 1);
                    }
                }
            }
        }
    }

    &__action {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0px;
        
        a {
            color: color(gy, 2);
            font-size: 13px;
            &:hover {
                color: color(wh, 1);
                text-decoration: underline;
            }
        }
    }

    &__help {
        @include media(xs) {
            padding: 0px 20px;
            margin-top: 10px;
        }
    }

    &__menu {
        display: flex;
        flex-wrap: wrap;
        @include media(xs) {
            display: block;
            columns: 2;
            padding: 0px 20px !important;
            li {
                margin-bottom: 10px;
            }
            a {
                text-decoration: underline;
            }
        }
        a {
            margin-right: 20px;
        }
        li {
            &:last-child {
                a {
                    margin-right: 0px;
                }
            }
        }
    }

    &__help {
        a {
            display: block;
        }
    }

    &__bottom {
        padding: 20px 0px;
        background: color(bk, 6);
        position: relative;
        @include media(xs) {
            text-align: center;
        }
        &:after,
        &:before {
            content: '';
            position: absolute;
            width: 100vw;
            left: -100vw;
            top: 0;
            bottom: 0;
            background: color(bk, 6);
        }

        &:before {
            left: auto;
            right: -100vw;
        }

        p {
            margin: 0px;
            color: color(gy, 2);
            font-size: 13px;
        }
    }
}