@mixin clearfix {
    &::after {
        clear: both;
        content: "";
        display: block;
    }
}


@mixin full-width($support-type: margin, $min-width:null) {
    @if $support-type=='margin' {
        margin-left: calc(-50vw + 50%);
        margin-right: calc(-50vw + 50%);
    }
    @if $support-type=='position' {
        width: 100vw;
        position: relative;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
    }
    @if $support-type=='translate' {
        width: 100vw;
        transform: translateX(calc((#{$min-width} - 100vw)/2));
    }
}

@mixin transition( $speed , $time ) {
    transition-duration: $speed;
    transition-timing-function: $time;
}


@mixin shadow($level: 1, $color: color(bk, 1)) {
    @if $level==0 {
        box-shadow: 0px 0px 0px 0px transparent;
    }

    @else if $level==1 {
        box-shadow: 0px 4px 5px rgba(77, 94, 109, 0.05);
    }
    @else if $level==2 {
        box-shadow: 0px 30px 60px rgba(54, 66, 83, 0.1);
    }
    @else if $level==3 {
        box-shadow: 0px 30px 60px rgba(54, 66, 83, 0.1);
    }
    @else if $level==4 {
        box-shadow: 0px 40px 100px rgba(34, 51, 94, 0.18);
    }
    @else if $level==5 {
        box-shadow: 0 19px 38px rgba($color,0.30), 0 15px 12px rgba($color,0.22);
    }
}


@mixin media($breakpoint) {
    @each $el, $item in $grid-breakpoints {
        @if $breakpoint==$el {
            @media screen and( max-width: map-get($grid-breakpoints, $el) - 1) {
                @content
            }
        }
    }
}

@mixin media_min($breakpoint) {
    @each $el, $item in $grid-breakpoints {
        @if $breakpoint==$el {
            @media screen and( min-width: map-get($grid-breakpoints, $el) - 1) {
                @content
            }
        }
    }
}

@mixin container($clearfix:false) {
    @include make-container($clearfix);
    @include make-container-max-widths(); 

}

@mixin icon($icon) {
    content: $icon;
    font-family: "icons";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    /* speak: none; only necessary if not using the private unicode range (firstGlyph option) */
    text-decoration: none;
    text-transform: none; 
}

@import 'breakpoints';
@import 'grid';
@import 'grid-framework';